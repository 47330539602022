@import "~bootstrap/dist/css/bootstrap.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";

body {
  font-family: "Roboto", sans-serif;
  color: #020230;
  overflow-x: auto;
  background-image: url("./assets/liquid-cheese.svg");
  background-attachment: fixed;
  background-size: cover;
  font-weight: 400;
}

/*header section*/
.App {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
}

#headerDiv {
  position: fixed;
  min-width: 240px;
  background-color: #ffffffd8;
  z-index: 5;
  width: 100%;
  box-shadow: 0px 2px 15px #00000025;
}

.navBrandWrapper {
  font-weight: bold;
  color: #1c19ec;
  font-size: 30px;
  text-transform: uppercase;
  margin: 10px 15px;
  margin-left: 40px;
  animation: fadeIn 1s ease-out forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.navBrand:hover {
  color: #1c19ec;
  transform: translate(2px, 1px);
  transition: transform 0.3s ease;
}

.navBar {
  --bs-navbar-color: #1c19ec;
  --bs-navbar-hover-color: #1c19ec;
  --bs-navbar-disabled-color: #1c19ec;
  --bs-navbar-brand-color: #1c19ec;
  --bs-navbar-brand-hover-color: #1c19ec;
  --bs-navbar-toggler-border-color: #1c19ec;
}

#responsive-navbar-nav {
  outline: none;
}

@media (min-width: 993px) {
  #responsive-navbar-nav {
    display: flex;
    justify-content: flex-end;
  }
}

.navbar-toggler-icon {
  margin-right: 30px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(28, 25, 236, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-toggler-icon:active {
  transition: transform 0.5s ease;
  transform: rotate(360deg);
}

.navbar-toggler {
  border: 0;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
  color: #1d19ec;
}

.nav-item {
  display: flex;
  justify-content: center;
  animation: fadeIn 1s ease-out forwards;
}

.nav-link {
  padding: 3px 15px;
  margin: 10px 15px;
  color: #1c19ec;
  text-align: center;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
}

.nav-link:hover {
  text-shadow:
    0 5 25px #1d19ec73,
    0 5 50px #1d19ec73,
    0 5 200px #1d19ec73;
  transform: translate(2px, 1px);
  transition:
    text-shadow 1s ease,
    transform 0.3s ease;
}

/*end header section*/

#loggedInNotice {
  color: #1c19ec;
  margin-top: 100px;
  margin-left: 15px;
  display: flex;
  flex-shrink: none;
  min-width: 240px;
  text-shadow: 5px 5px 30px #0000005b;
  animation: fadeIn 1s ease-out forwards;
  font-weight: 500;
}

/*login section*/

@keyframes flyInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.inputContainer {
  min-width: 240px;
}

.loginContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 200px;
  margin-top: 150px;
  color: #1c19ec;
  animation: flyInFromLeft 0.5s ease-out forwards;
}

.loginHeader {
  font-weight: bold;
  color: #1c19ec;
  font-size: 24px;
  text-transform: uppercase;
  margin: 10px 15px;
  display: flex;
  flex-wrap: none;
}

.loginForm {
  font-weight: bold;
  color: #1c19ec;
  font-size: 14px;
  text-transform: uppercase;
  margin: 10px 15px;
  margin-top: 0px;
}

.inputContainer {
  display: flex;
  flex-direction: column;
}

.inputBox {
  background-color: transparent;
  border: none;
  border-radius: 5px;
  box-shadow: 10px 10px 20px #00000013;
  margin-bottom: 10px;
}

.inputBox:focus {
  outline: 2px solid #1d19ec96;
}

.login-button {
  font-family: inherit;
  background-color: transparent;
  border: none;
  transition-duration: 0.3s;
  transition-property: box-shadow;
  transform: perspective(1px) translateZ(0);
  padding: 0.25rem 0.5rem;
  color: #1c19ec;
  border-radius: 2px;
  box-shadow: 5px 5px 40px rgba(0, 0, 0, 0.15);
  font-weight: bold;
}

.login-button:hover {
  transition:
    transform 0.3s ease,
    backdrop-filter 1s ease;
  transform: translate(2px, 1px);
  backdrop-filter: saturate(5);
}

.signUpCallToAction {
  margin: 10px 15px;
  display: flex;
  font-size: 12px;
}

.signUpLink {
  cursor: pointer;
  color: rgb(28, 25, 236);
}

.signUpLink:hover {
  color: #ffa940;
}

.signUpContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 10rem;
  margin-top: 150px;
  animation: flyInFromLeft 0.5s ease-out forwards;
}

#logoutContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 5rem;
  margin-left: 15px;
  animation: flyInFromLeft 0.5s ease-out forwards;
}

#logout-button {
  font-family: inherit;
  background-color: transparent;
  border: none;
  margin-top: 10px;
  padding: 0.25rem 0.5rem;
  color: #1c19ec;
  border-radius: 2px;
  box-shadow: 5px 5px 40px rgba(0, 0, 0, 0.15);
  font-weight: bold;
}

#logout-button:hover {
  transition:
    transform 0.3s ease,
    backdrop-filter 0.5s ease,
    box-shadow 0.3s ease;
  transform: translate(2px, 1px);
  backdrop-filter: saturate(5);
}

.load-more-button {
  font-family: inherit;
  border-color: #1d19ec63;
  border: none;
  margin-top: 10px;
  transform: perspective(1px) translateZ(0);
  padding: 0.25rem 0.5rem;
  color: #1c19ec;
  border-radius: 3px;
  margin: 15px 15px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.192);
  font-weight: bold;
  background-color: transparent;
  transition: transform 0.3s ease;
  backdrop-filter: none;
}

.load-more-button:hover {
  backdrop-filter: saturate(5);
  transition: backdrop-filter 0.5s ease;
  transform: translate(2px, 1px);
}

/*end login section*/

/*favorites section*/

#pleaseLoginContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 240px;
  margin: 20px 15px;
}

#pleaseLoginNotice {
  color: #1c19ec;
}

#noFavoritesContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 240px;
  margin: 150px 15px;
}

#noFavorites {
  color: #1c19ec;
  animation: flyInFromLeft 0.5s ease-out forwards;
}

/*markets section*/
.MarketsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: fadeIn 1s ease-out forwards;
}

.TableContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0;
  border-radius: 15px;
  overflow-x: auto;
  max-width: 100%;
  position: relative;
}

table {
  margin: 0px;
  border-collapse: separate;
  border-radius: 10px;
  padding: 0;
}

th,
td {
  padding: 0.8rem;
  text-align: left;
}

thead, tbody {
  width: 100%;
  white-space: nowrap;
}

.marketsTable {
  background-color: transparent;
  backdrop-filter: blur(10px) saturate(131%);
}

th {
  background-color: #23c94cc4;
  font-weight: bold;
  text-align: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

tbody tr:last-child td {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

tr {
  border: none;
}

.coinGeckoLink {
  text-decoration: none;
  color: #f2f2f2;
}

td {
  color: #f2f2f2;
  text-align: center;
  background-color: rgba(17, 25, 40, 0.59);
  text-shadow: 1px 4px 100px #000000;
}

#viewChartBtn {
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 15px;
  color: #ffffff;
  transform-origin: center top;
  transition:
    color 0.5s ease,
    transform 0.5s ease;
}

#viewChartBtn:hover {
  color: #1c19ec;
  transform: scale(1.2);
}

#favBtn {
  color: #fcdf3e;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 20px;
}

#favBtn:hover {
  transform: scale(1.5);
  transition: transform 0.5s ease;
}

.Modal {
  position: absolute;
  top: 20%;
  left: 10%;
  right: 10%;
  background: #d3d3d3;
  padding: 20px;
  border-radius: 10px;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.apexcharts-zoom-icon svg {
  stroke: #1c19ec;
}

#chartBtnContainer {
  display: flex;
  justify-content: right;
}

#chartBtn {
  color: #1c19ec;
  border-radius: 5px;
  border: none;
  transition: text-shadow 1s ease;
  background-color: #d3d3d3;
  font-weight: bold;
}

#chartBtn:hover {
  text-shadow: 0 5 5px #1d19ec;
  color: rgb(28, 25, 236);
  transform: translate(1px, 1px);
  transition: transform 0.3s ease;
}
